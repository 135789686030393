.App {
  text-align: center;
}

.container {
  padding: 60px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
}
